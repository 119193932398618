import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

function FlatCallBack(props) {
	const {
		id = "flat-call-back",
		title = "Looking for Professional Approach and Quality Services ?",
		hideButton = false,
	} = props;

	const button = hideButton ? null : (
		<LinkContainer to="/contact">
			<Button variant="primary" className="contact-button">Contact us today</Button>
		</LinkContainer>
	);

	return (
		<section id={id} className="flat-call-back">
			<Container>
				<Row>
					<Col md={12}>
                        <div className="title-section">
                            <h6>{title}</h6>
                        </div>
						{button}
					</Col>
				</Row>
			</Container>
		</section>
	);
}

FlatCallBack.propTypes = {
	id: PropTypes.string,
	subheading: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	hideAppointmentButton: PropTypes.bool,
	backgroundImage: PropTypes.string
}

export default FlatCallBack

