import React from "react";
import { Container, Col, Row, Button, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import WebsiteData from "data.json";

import Service from "components/Service";
import ContactForm from "components/ContactForm";
import Department from "components/Department";
import NewsArticle from "components/NewsArticle";
import MediplusInNumbers from "components/MediplusInNumbers";
import Hero from "components/Hero";
import HashLinkContainer from "components/HashLinkContainer";

export default function Home() {
	document.title = "Home || Jozini Municipality";

	return (
		<React.Fragment>
			<Hero
				id="Home"
				subheading="Welcome to Jozini Municipality"
				title="Sustainable service for all and good governance"
				description="Jozini is an administrative area in the Umkhanyakude 
					District of KwaZulu-Natal in South Africa. Significant portions 
					of Jozini have been neglected in terms of economic development. 
					There is a great disparity between the level of service and 
					infrastructure provision between settlement nodes, such as the 
					towns of Mkuze and Jozini, and the surrounding rural areas."
				backgroundImage={require("images/slide1.jpg")}
			/>
			<section id="About">
				<Container>
					<Row className="py-3 py-md-0">
						<Col md={4} lg={5}>
							<Image
								src={require("images/welcome.jpg")}
								className="about-doctor-image"
							/>
						</Col>
						<Col
							md={8}
							lg={7}
							className="align-self-center pl-md-3 mt-3 mt-md-0 py-0 py-md-4"
						>
							<p className="subheading">About Us</p>
							<h2 className="mb-2">
								We Are <span className="text-primary">Jozini Municipality</span>
							</h2>
							<p className="mb-2">
								Jozini has a comparative advantage in terms of its favorable 
								location near the N2 and its abundant natural features, 
								which are potential tourism assets.
							</p>
							<p className="mb-3">
								Tourism has been prioritized as the major economic 
								development opportunity and its success is dependent on 
								local community initiatives as well as the natural beauty of 
								the surounding landscape. 
							</p>
							<LinkContainer to="/about/jozini-municipality">
								<Button variant="primary" className="mr-2 mb-2">
									Learn more
								</Button>
							</LinkContainer>
							<HashLinkContainer smooth to="/contact">
								<Button variant="secondary" className="mb-2">
									Contact us
								</Button>
							</HashLinkContainer>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="Services" className="bg-light">
				<Container>
					<Row className="px-sm-2">
						<Col
							lg={{ span: 5, order: 12 }}
							className="services-description mt-3 mt-lg-0 py-3 py-lg-5 px-sm-3"
						>
							<p className="subheading">What we take pride in</p>
							<h2 className="mb-2">Our Tourism Activities</h2>
							<p className="mb-2">
								Jozini is also regarded as the Adventure Paradise. Experience the hiking at the
								Ghost Mountain and at the Border Cave.
							</p>
							<ContactForm className="d-none d-sm-block" />
						</Col>
						<Col lg={7} className="mt-sm-3 py-0 mt-lg-0 py-lg-5 pr-lg-3">
							<Row>
								{WebsiteData.services.map(service => {
									return <Service service={service} key={service.id} />;
								})}
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="YourHealth">
				<Container>
					<Row className="py-5 justify-content-center">
						<Col md={10} lg={8} className="text-center text-light">
							<p className="subheading">Tourism</p>
							<h2 className="mb-2">The Jozini Dam</h2>
							<p className="mb-2">
								The Jozini Dam (previously called the Pongolapoort Dam) 
								is build on the Pongola River was completed in 1974 and 
								covers an area of 17 000 hectares when the dam levels 
								are at 100%.  The dam wall is 70m high  is build across 
								the Pongola River gorge at the town of Jozini.
							</p>
							<a href="/about/jozini-dam" className="btn btn-primary" variant="primary">Learn more</a>
						</Col>
					</Row>
				</Container>
			</section>

			<section id="Departments">
				<Container>
					<Row className="px-sm-2 py-3 py-lg-0">
						<Col
							lg={5}
							className="departments-description py-3 py-lg-4 px-sm-3"
						>
							<p className="subheading">Jozini Executive Mayor</p>
							<h2 className="mb-2">Councillor DP Mabika</h2>
							<p className="mb-2">
								These were words of Jozini Mayor, His Worship Councillor
								DP Mabika, after introducing the newly appointed Municipal
								Manager, Mr Jaconia Mngomezulu...
							</p>
							<a className="btn btn-primary" href="/municipality/municipal-manager" variant="outline-primary">Learn more</a>
						</Col>
						<Col lg={7}>
							<Row>
								{WebsiteData.departments.map(department => {
									return (
										<Department department={department} key={department.id} />
									);
								})}
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			{/* <section id="Team">
				<Container className="py-3 py-md-5">
					<Row className="justify-content-center mb-2">
						<Col md={8} lg={6} className="text-center">
							<h2 className="mb-2">Our Team</h2>
							<p className="mb-2">
								Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit
								asperiores adipisci dolorem voluptatum.
							</p>
						</Col>
					</Row>
					<Row>
						{WebsiteData.team.map(member => {
							return <Doctor doctor={member} key={member.id} />;
						})}
					</Row>
				</Container>
			</section> */}

			<section id="LatestNews">
				<Container className="py-3 py-md-5">
					<Row className="justify-content-center mb-2">
						<Col md={8} lg={6} className="text-center">
							<div className="title-section">
								<div className="top-title">Tips, News &amp; Events</div>
								<h2 className="title">Read Our Latest News</h2>
								<div className="title-line"></div>
								<div className="title-content">
									<p>
										Read our news blog for all the latest municipality news, 
										tips, speeches, government news and events
									</p>
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						{WebsiteData.articles.map(article => {
							return <NewsArticle article={article} key={article.id} />;
						})}
					</Row>
				</Container>
			</section>
			<MediplusInNumbers />
		</React.Fragment>
	);
}
