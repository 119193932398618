import React from 'react';

import WebsiteData from "data.json";

const NewsSideBar = () => {
    const articleData = WebsiteData.articles;
    const articleImage = require(`images/person_1.jpg`);
    return (
        <div className="sidebar">
            <div className="widget">
                <h4 className="widget-title">Categories</h4>
                <div className="categories">
                    <ul>
                        <li className="cat-item-29"><a href="/blog">All Topics</a></li>
                        <li className="cat-item-30"><a href="/blog/category/1/newsletters">Newsletters</a></li>
                        <li className="cat-item-1"><a href="/blog/category/2/council-notices">Council Notices</a></li>
                        <li className="cat-item-32"><a href="/blog/category/3/speeches">Speeches</a></li>
                        <li className="cat-item-33"><a href="/blog/category/4/photos">Photos</a></li>
                        <li className="cat-item-34"><a href="/blog/category/5/bylaws">ByLaws</a></li>
                        <li className="cat-item-34"><a href="/blog/category/6/minutes">Minutes</a></li>
                    </ul>
                </div>
            </div>
            <div className="widget">
                <h4 className="widget-title">Recent Posts</h4>
                <div className="recent-posts">
                    <ul className="recent-news">  
                        {articleData.map(article => {
                            return <li>
                                <div className="thumb">
                                    <span className="overlay-pop"></span>
                                    <a href="/">
                                        <img width="132" height="132" src={articleImage} alt={article.title} />
                                    </a>
                                </div>
                                <div className="text">
                                    <p className="date-1">
                                        <time className="date updated" dateTime="2018-04-09T10:08:26+00:00">{article.date}</time>
                                    </p>
                                    <h4>
                                        <a href="/" rel="bookmark">{article.title}</a>
                                    </h4>                        
                                    <p className="date-2">
                                        <time className="date updated" dateTime="2018-04-09T10:08:26+00:00">{article.date}</time>
                                    </p>
                                </div>
                            </li>;
                        })}     
                    </ul>
                </div>
            </div>
            <div className="widget">
                <h4 className="widget-title">Archives</h4>
                <div className="archives">
                    <ul>
                        <li><a href="/blog/archives/2018-04">April 2018</a>&nbsp;(9)</li>
                        <li><a href="/blog/archives/2018-03">March 2018</a>&nbsp;(1)</li>
                    </ul>
                </div>
            </div>
            <div className="widget">
                <h4 className="widget-title">Tags</h4>
                <div className="tagcloud">
                    <a href="/">Municipality</a>
                    <a href="/">Business</a>
                </div>
            </div>
        </div>
    );
}

export default NewsSideBar;
