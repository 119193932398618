import React from "react";

import Hero from "components/Hero";

export default function LegalTerms() {
	window.scrollTo(0, 0);
	document.title = "Terms of Use || Jozini Municipality";

	return (
			<Hero
				subheading="Terms of Use"
				title="Lorem ipsum dolor mit"
				description="Minima dolorum vero quas ullam exercitationem animi. Soluta dicta excepturi temporibus assumenda."
				backgroundImage={require("images/page-title.jpg")}
				hideAppointmentButton
			/>
	);
}