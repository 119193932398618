import React from "react";
import {
	Container,
	Col,
	Nav,
	Navbar,
	NavDropdown,
	Row,
} from "react-bootstrap";
import HashLinkContainer from "components/HashLinkContainer";

// custom scrolling function so that the header doesn't overlap anchor content
const topMinusHeaderHeight = el => {
	const scrollY =
		el.offsetTop - document.getElementById("site-navbar").offsetHeight;
	window.scroll({ top: scrollY, behavior: "smooth" });
};

export default function Navigation() {
	const [dropdownOpen, setDropdownOpen] = React.useState({});

	const onMouseEnter = (e) => {
		if (e.target.text) {
			const name = e.target.text.toLowerCase();
			setDropdownOpen({ [name]: true });
		}
	}

	const onMouseLeave = (e) => {
		if (e.target.text) {
			const name = e.target.text.toLowerCase();
			setDropdownOpen({ [name]: false });
		}
	}

	return (
		<>
			<Container fluid className="nav-top-bar">
				<Container>
					<Row>
						<Col xs={6} className="top-bar-left">
							<ul>
								<li>
									<i className="fa fa-phone"></i>
									+27 (0) 35 572 1292
								</li>
								<li>
									<i className="fa fa-envelope"></i>
									info@jozini.gov.za
								</li>
							</ul>
						</Col>
						<Col xs={6} className="top-bar-right">
							<ul>
								<li className="facebook">
									<a href="https://web.facebook.com/JoziniMunicipality/" target="_blank" rel="noopener noreferrer" title="Jozini Municipality on Facebook">
										<i className="fa fa-facebook"></i>                            
									</a>
								</li>
								<li className="twitter">
									<a href="https://twitter.com/jozinilocal" target="_blank" rel="noopener noreferrer" title="Jozini Municipality on Twitter">
										<i className="fa fa-twitter"></i>                            
									</a>
								</li>
								<li className="instagram">
									<a href="/" target="_blank" rel="noopener noreferrer" title="Jozini Municipality on Instagram">
										<i className="fa fa-instagram"></i>                            
									</a>
								</li>
								<li className="pinterest">
									<a href="/" target="_blank" rel="noopener noreferrer" title="Jozini Municipality on Pinterest">
										<i className="fa fa-pinterest"></i>                            
									</a>
								</li>
							</ul>
						</Col>
					</Row>
				</Container>
			</Container>
			<Navbar
				id="site-navbar"
				sticky="top"
				bg="light"
				collapseOnSelect
				expand="lg"
			>
				<Container>
					<HashLinkContainer scroll={topMinusHeaderHeight} to="/">
						<Navbar.Brand className="font-weight-bold">Jozini Municipality</Navbar.Brand>
					</HashLinkContainer>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="ml-auto" defaultActiveKey="/">
							<HashLinkContainer scroll={topMinusHeaderHeight} to="/">
								<Nav.Link>Home</Nav.Link>
							</HashLinkContainer>
							<NavDropdown title="About" id="nav-about" show={dropdownOpen.about ? dropdownOpen.about : false} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
								<NavDropdown.Item href="/about/history">History</NavDropdown.Item>
								<NavDropdown.Item href="/about/jozini-dam">Jozini Dam</NavDropdown.Item>
								<NavDropdown.Item href="/about/tourism">Tourism</NavDropdown.Item>
								<NavDropdown.Item href="/about/jozini-municipality">The Municipality</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="Municipality" id="nav-municipality" show={dropdownOpen.municipality ? dropdownOpen.municipality : false} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
								<NavDropdown.Item href="/municipality/annual-reports">Annual Reports</NavDropdown.Item>
								<NavDropdown.Item href="/municipality/bursaries">Bursaries</NavDropdown.Item>
								<NavDropdown.Item href="/municipality/counsellors">Counsellors</NavDropdown.Item>
								<NavDropdown.Item href="/municipality/exco">Exco</NavDropdown.Item>
								<NavDropdown.Item href="/municipality/mayor">Mayor</NavDropdown.Item>
								<NavDropdown.Item href="/municipality/munucipal-manager">Municipal Manager</NavDropdown.Item>
								<NavDropdown.Item href="/municipality/organogram">Organogram</NavDropdown.Item>
								<NavDropdown.Item href="/municipality/performance-plans">Performance Plans</NavDropdown.Item>
								<NavDropdown.Item href="/municipality/vision-and-mission">Vision and Mission</NavDropdown.Item>
								{/* <NavDropdown.Divider />
								<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
							</NavDropdown>
							<NavDropdown title="SCM" id="nav-scm" show={dropdownOpen.scm ? dropdownOpen.scm : false} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
								<NavDropdown.Item href="/scm/awarded-tenders">Awarded Tenders</NavDropdown.Item>
								<NavDropdown.Item href="/scm/bid-registers">Bid Registers</NavDropdown.Item>
								<NavDropdown.Item href="/scm/supplier-database-forms">Supplier Database Forms</NavDropdown.Item>
								<NavDropdown.Item href="/scm/tenders">Tenders</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="Departments" id="nav-departments" show={dropdownOpen.departments ? dropdownOpen.departments : false} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
								<NavDropdown.Item href="/departments/corporate-services">Corporate Services</NavDropdown.Item>
								<NavDropdown.Item href="/departments/community-services">Community Services</NavDropdown.Item>
								<NavDropdown.Item href="/departments/finance">Finance</NavDropdown.Item>
								<NavDropdown.Item href="/departments/idp">IDP</NavDropdown.Item>
								<NavDropdown.Item href="/departments/library-services">Library Services</NavDropdown.Item>
								<NavDropdown.Item href="/departments/local-economic-development">Local Economic Developement</NavDropdown.Item>
								<NavDropdown.Item href="/departments/planning-economic-development">Planning &amp; Economic Development Services</NavDropdown.Item>
								<NavDropdown.Item href="/departments/sdbip">SDBIP</NavDropdown.Item>
								<NavDropdown.Item href="/departments/sdf">SDF</NavDropdown.Item>
								<NavDropdown.Item href="/departments/section-12">Section 52</NavDropdown.Item>
								<NavDropdown.Item href="/departments/section-71">Section 71</NavDropdown.Item>
								<NavDropdown.Item href="/departments/thusong-centre">Thusong Centre</NavDropdown.Item>
								<NavDropdown.Item href="/departments/valuation">Valuation</NavDropdown.Item>
							</NavDropdown>
							<NavDropdown title="Media" id="nav-media" show={dropdownOpen.media ? dropdownOpen.media : false} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
								<NavDropdown.Item href="/media/category/1/newsletters">Newsletters</NavDropdown.Item>
								<NavDropdown.Item href="/media/category/2/council-notices">Council Notices</NavDropdown.Item>
								<NavDropdown.Item href="/media/category/3/speeches">Speeches</NavDropdown.Item>
								<NavDropdown.Item href="/media/category/4/photos">Photos</NavDropdown.Item>
								<NavDropdown.Item href="/media/category/5/bylaws">Bylaws</NavDropdown.Item>
								<NavDropdown.Item href="/media/category/6/minutes">Minutes</NavDropdown.Item>
							</NavDropdown>
							<HashLinkContainer scroll={topMinusHeaderHeight} to="/policies">
								<Nav.Link>Policies</Nav.Link>
							</HashLinkContainer>
							<HashLinkContainer scroll={topMinusHeaderHeight} to="/vacancies">
								<Nav.Link>Vacancies</Nav.Link>
							</HashLinkContainer>
							<HashLinkContainer scroll={topMinusHeaderHeight} to="/contact">
								<Nav.Link>Contact Us</Nav.Link>
							</HashLinkContainer>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}
