import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import WebsiteData from "data.json";
import Hero from "components/Hero";
import MediplusInNumbers from "components/MediplusInNumbers";
import NewsArticle from "components/NewsArticle";
import NewsSideBar from "components/NewsSideBar";

export default function BlogCategory() {
    document.title = "Latest News by Category || Jozini Municipality";
    const articleData = WebsiteData.articles;
    window.scrollTo(0, 0);

	return (
		<>
            <Hero
                subheading="Latest news &amp; events"
                title="Important News, Events &amp; Inside Scoops"
                description="Minima dolorum vero quas ullam exercitationem animi. Soluta dicta excepturi temporibus assumenda."
                backgroundImage={require("images/page-title.jpg")}
                hideAppointmentButton
            />
			<section id="LatestNews">
				<Container className="py-3 py-md-5">
					<Row>
                        <Col sm={9}>
                            <Row>
                                {articleData.map(article => {
                                    return <NewsArticle
                                        article={article}
                                        key={article.id.toString()} 
                                        classes={{
                                            sm: 6,
                                            md: 6,
                                        }}
                                    />;
                                })}
                            </Row>
                            <Row>
                                <Col>
                                    <div>
                                        <nav className="navigation  paging-navigation pager-numeric" role="navigation">
                                            <div className="pagination loop-pagination">
                                                <a class="prev page-numbers" href="/"><i class="fa fa-angle-double-left"></i>Prev</a>
                                                <a className="page-numbers" href="/">1</a>
                                                <span className="page-numbers current">2</span>
                                                <a className="page-numbers" href="/">3</a>
                                                <a className="next page-numbers" href="/">Next<i class="fa fa-angle-double-right"></i></a>
                                            </div>
                                        </nav>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3}>
                            <NewsSideBar />
                        </Col>
					</Row>
				</Container>
			</section>
			<MediplusInNumbers />
		</>
	);
}
