import React from "react";
import { Container, Col, Row, Button, Image } from "react-bootstrap";

import Hero from "components/Hero";
import ContactForm from "components/ContactForm";

export default function Appointment() {
	window.scrollTo(0, 0);
	document.title = "Contact Us || Jozini Municipality";

	return (
		<React.Fragment>
            <Hero
                subheading="Get in touch"
                title="How can we help you?"
                description="Minima dolorum vero quas ullam exercitationem animi. Soluta dicta excepturi temporibus assumenda."
                backgroundImage={require("images/page-title.jpg")}
                hideAppointmentButton
            />
            <section id="ContactUs">
                <Container>
                    <Row>
                        <Col>
                            <div className="title-section text-center">
                                <div className="top-title">
                                    Get in Touch
                                </div>
                                <h2 className="title">
                                    Let's Talk about Your Enquiry
                                </h2>	
                                <div className="title-line"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-4">
                        <Col sm={6}>
                            Map
                        </Col>
                        <Col sm={6}>
                            <ContactForm />
                        </Col>
                    </Row>
                    <Row className="mt-5 mb-5">
                        <Col sm={4}>
                            <div className="icon-box">
                                <div className="icon-box-icon">
                                    <div className="icon">
                                        <span className="fa fa-phone"></span>
                                    </div>
                                </div>
                                <div className="icon-box-content">
                                    <h4 className="title">Phone</h4>
                                    <ul>
                                        <li>+27 (0) 35 572 1292</li>
                                        <li>+27 (0) 35 572 1266</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="icon-box">
                                <div className="icon-box-icon">
                                    <div className="icon">
                                        <span className="fa fa-envelope-open"></span>
                                    </div>
                                </div>
                                <div className="icon-box-content">
                                    <h4 className="title">Message</h4>
                                    <ul>
                                        <li>info@jozini.gov.za</li>
                                        <li>support@jozini.gov.za</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className="icon-box">
                                <div className="icon-box-icon">
                                    <div className="icon">
                                        <span className="fa fa-map-marker"></span>
                                    </div>
                                </div>
                                <div className="icon-box-content">
                                    <h4 className="title">Our Location</h4>
                                    <ul>
                                        <li>Jozini municipality, P/Bag x028, Jozini 3969</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>     
            </section>
		</React.Fragment>
	);
}