import React from "react";
import { Container, Col, Row, Button, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import WebsiteData from "data.json";
import Hero from "components/Hero";
import HashLinkContainer from "components/HashLinkContainer";

export default function About(props) {
	window.scrollTo(0, 0);
	const page = props.match.params.page;
	const {
		subheading,
		title,
		summary,
	} = WebsiteData.pages.about[page];
	document.title = title;

	return (
		<React.Fragment>
			<Hero
				subheading={subheading}
				title={title}
				description={summary}
				backgroundImage={require("images/page-title.jpg")}
				hideAppointmentButton
			/>
			<section id="About">
				<Container>
					<Row className="py-3 py-md-0">
						<Col md={4} lg={5}>
							<Image
								src={require("images/about.jpg")}
								className="about-doctor-image"
							/>
						</Col>
						<Col
							md={8}
							lg={7}
							className="align-self-center pl-md-3 mt-3 mt-md-0 py-0 py-md-4"
						>
							<p className="subheading">Learn more</p>
							<h2 className="mb-2">
								About <span className="text-primary">Jozini Municipality</span>
							</h2>
							<p className="mb-2">
								Jozini has a comparative advantage in terms of its favorable 
								location near the N2 and its abundant natural features, 
								which are potential tourism assets.
							</p>
							<p className="mb-3">
								Tourism has been prioritized as the major economic 
								development opportunity and its success is dependent on 
								local community initiatives as well as the natural beauty of 
								the surounding landscape. 
							</p>
							<HashLinkContainer smooth to="/contact">
								<Button variant="secondary" className="mb-2">
									Contact Us
								</Button>
							</HashLinkContainer>
						</Col>
					</Row>
				</Container>
			</section>
		</React.Fragment>
	);
}