import React from 'react'
import { Form, Button } from "react-bootstrap";

export default function ContactForm() {
	const handleContact = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		const formData = {
			subject: form.subject.value,
			name: form.name.value,
			email: form.email.value,
			message: form.message.value,
		};
		console.log(formData);
	};

	return (
		<Form onSubmit={handleContact}>
			<Form.Group controlId="Subject">
				<Form.Label srOnly>Subject</Form.Label>
				<Form.Control type="text" name="subject" placeholder="Subject" />
			</Form.Group>

			<Form.Group controlId="Name">
				<Form.Label srOnly>Name</Form.Label>
				<Form.Control type="text" name="name" placeholder="Enter your name" />
			</Form.Group>

			<Form.Group controlId="Email">
				<Form.Label srOnly>Email address</Form.Label>
				<Form.Control type="email" name="email" placeholder="Enter your email" />
				<Form.Text className="text-muted">
					We'll never share your email with anyone else.
				</Form.Text>
			</Form.Group>

			<Form.Group controlId="Message">
				<Form.Label srOnly>Your messsage</Form.Label>
				<Form.Control
					as="textarea"
					placeholder="How can we help you?"
					rows="3"
					name="message"
				/>
			</Form.Group>

			<Button variant="primary" type="submit">
				Send Message
			</Button>
		</Form>
	);
}
