import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navigation from "components/Navigation";
import FlatCallBack from 'components/FlatCallBack';
import Footer from "components/Footer";

import Home from "pages/Home";
import ContactUs from "pages/ContactUs";
import About from "pages/About/Index";
import VacancyList from "pages/VacancyList";
import Municipality from "pages/Municipality";
import SCM from "pages/SCM.js";
import Departments from "pages/Departments";
import Policies from "pages/Policies";

// Legal
import LegalTerms from "pages/Legal/Terms";
import LegalPrivacy from "pages/Legal/Privacy";

// Blog section
import Media from "pages/Media/Index";
import MediaArticle from "pages/Media/MediaArticle";
import MediaCategory from "pages/Media/MediaCategory";
import MediaTag from "pages/Media/MediaTag";

export default function App() {
	return (
		<Router basename={process.env.PUBLIC_URL}>
			<Navigation />
			<main>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route path="/media/article/:id/:title" component={MediaArticle} />
					<Route path="/media/category/:id/:category" component={MediaCategory} />
					<Route path="/media/tag/:id/:tag" component={MediaTag} />
					<Route path="/media" component={Media} />
					<Route path="/about/:page" component={About} />
					<Route path="/scm/:page" component={SCM} />
					<Route path="/municipality/:page" component={Municipality} />
					<Route path="/departments/:permakey" component={Departments} />
					<Route path="/vacancies" component={VacancyList} />
					<Route path="/policies" component={Policies} />
					<Route path="/legal/terms" component={LegalTerms} />
					<Route path="/legal/privacy" component={LegalPrivacy} />
					<Route path="/contact" component={ContactUs} />
				</Switch>
			</main>
			<FlatCallBack title="Looking for Professional Approach and Quality Services ?" />
			<Footer />
		</Router>
	)
}
