import React from "react";

import Hero from "components/Hero";

export default function Municipality() {
	window.scrollTo(0, 0);
	document.title = "Municipality || Jozini Municipality";

	return (
			<Hero
				subheading="Municipality"
				title="Municipality"
				description="Minima dolorum vero quas ullam exercitationem animi. Soluta dicta excepturi temporibus assumenda."
				backgroundImage={require("images/page-title.jpg")}
				hideAppointmentButton
			/>
	);
}
