import React from "react";

import WebsiteData from "data.json";
import MediplusInNumbers from "components/MediplusInNumbers";
import { Container, Row, Col, Image } from "react-bootstrap";

export default function BlogArticle(props) {
	const id = props.match.params.id;
	const articleData = WebsiteData.articles.find(
		article => article.id.toString() === id
	);

	window.scrollTo(0, 0);
	document.title = articleData.title + " || Jozini Municipality";

	return (
		<>
			<section>
				<Container className="py-3 py-md-5">
					<Row>
						<Col md={7} className="pr-md-3">
							<p className="subheading">{articleData.category}</p>
							<h2 className="mb-2">{articleData.title}</h2>
                            <p className="mb-2">
                                {articleData.description}
                            </p>
						</Col>
						<Col md={5} className="d-none d-md-block">
							<Image src={require("images/welcome.jpg")} fluid />
						</Col>
					</Row>
				</Container>
			</section>
			<MediplusInNumbers />
		</>
	);
}
