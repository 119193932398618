import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default function MediplusInNumbers() {
	return (
		<section id="MediplusInNumbers">
			<Container>
				<Row className="py-3 py-md-5 text-light align-items-center">
					<Col md={6}>
						<p className="subheading">Jozini Statistics</p>
						<h2 className="mb-2">Over 200 thousand commuters</h2>
						<p>
							Population, 198 215, 186 502. Age Structure. Population under 15, 41.5%, 41.3%. 
							Population 15 to 64, 55.0%, 54.8%. Population over 65, 3.4%, 3.9%
						</p>
						<LinkContainer to="/contact">
							<Button variant="secondary">Contact us</Button>
						</LinkContainer>
					</Col>
					<Col md={6} className="d-none d-sm-block">
						<Row>
							<Col sm={3} md={6} className="py-3 text-center">
								<strong>800 km</strong>
								<span>Paved Road Infrastructure</span>
							</Col>
							<Col sm={3} md={6} className="py-3 text-center">
								<strong>200 thousand</strong>
								<span>Inhabitants</span>
							</Col>
							<Col sm={3} md={6} className="py-3 text-center">
								<strong>20</strong>
								<span>Wards</span>
							</Col>
							<Col sm={3} md={6} className="py-3 text-center">
								<strong>1</strong>
								<span>Customer Care Centre</span>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
}
