import React from 'react'
import {
	Button,
	Container,
	Col,
	Form,
	Input,
	Row,
} from 'reactstrap';

export default function Footer() {
	const handleSubscribe = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		const formData = {
			email: form.email.value,
		};
		console.log(formData);
	};

	return (
		<section className="footer dark">
			<Container>
				<div className="footer-top">
					<Row>
						<Col xs={12} md={3}>
							<h1 className="widget-title">About Us</h1>
							<p className="about-text">
								Jozini is an administrative area in the Umkhanyakude District
								of KwaZulu-Natal in South Africa.
							</p>
							<div className="footer-contact">
								<ul>
									<li><a href="tel:+27355721292"><i className="fa fa-phone"></i> Tel: +27 (0) 35 572 1292</a></li>
									<li><a href="tel:+27355721266"><i className="fa fa-print"></i> Fax: +27 (0) 35 572 1266  </a></li>
									<li><a href="mailto:info@jozini.gov.za"><i className="fa fa-envelope"></i> Email: info@jozini.gov.za</a></li>
								</ul>
							</div>
							<div className="footer-social">
								<ul>
									<li><a href="https://web.facebook.com/JoziniMunicipality/" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a></li>
									<li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
									<li><a href="https://twitter.com/jozinilocal" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a></li>
									<li><a href="https://www.google.com/" target="_blank" rel="noopener noreferrer"><i className="fa fa-google"></i></a></li>
									<li><a href="https://www.pinterest.com/" target="_blank" rel="noopener noreferrer"><i className="fa fa-pinterest"></i></a></li>
								</ul>
							</div>
							<br/>
							<div className="footer-contact">
								<ul>
									<li><a>Tuesdays - Fridays: 10h00 - 17h00</a></li>
									<li><a>Saturdays: 10h00 - 13h00</a></li>
									<li><a>Sundays: 14h00 - 17h00</a></li>
								</ul>
							</div>
						</Col>
						<Col xs={12} md={3}>
							<h1 className="widget-title">Quick Links</h1>
							<ul className="footer-nav">
								<li><a href="/"><i className="icon-right-open"></i> Home</a></li>
								<li><a href="/"><i className="icon-right-open"></i> About Us</a></li>
								<li><a href="/vacancies"><i className="icon-right-open"></i> Vacancies</a></li>
								<li><a href="/"><i className="icon-right-open"></i> Open Tenders</a></li>
								<li><a href="/"><i className="icon-right-open"></i> RFQ's</a></li>
							</ul>
						</Col>
						<Col xs={12} md={3}>
							<h1 className="widget-title">Explore</h1>
							<ul className="footer-nav">
								<li><a href="/"><i className="icon-right-open"></i> By-laws</a></li>
								<li><a href="/"><i className="icon-right-open"></i> Services</a></li>
								<li><a href="/"><i className="icon-right-open"></i> Forms</a></li>
								<li><a href="/"><i className="icon-right-open"></i> Annual Reports</a></li>
								<li><a href="/"><i className="icon-right-open"></i> Help Centre</a></li>
							</ul>
						</Col>
						<Col xs={12} md={3}>
							<h1 className="widget-title">Subscribe</h1>
							<div className="subscribe">
								<p>Subscribe to Our Newsletter to get Important News, Events, Amazing Services &amp; Inside Scoops:</p>
								<Form onSubmit={handleSubscribe}>
									<div className="input-group">
										{/* <div className="input-group-prepend">
											<div className="input-group-text">	
												<i className="icon-email2"></i>
											</div>
										</div> */}
										<Input type="email" id="email" name="email" className="form-control required email" placeholder="Enter your email" />
										<div className="input-group-append">
											<Button color="primary" type="submit">GO</Button>
										</div>
									</div>
								</Form>
								<div className="subscribe-form-result" />
							</div>
						</Col>
					</Row>
				</div>
			</Container>
			<Container className="copyrights" fluid>
				<Container>
					<Row>
						<Col sm={6}>
							<span>Jozini Municipality</span> &copy; 2019. All Rights Reserved
						</Col>
						<Col sm={6} className="terms-text">
							<a href="/legal/terms">Terms of use</a>
							<a href="/legal/privacy">Privacy policy</a>
						</Col>
					</Row>
				</Container>
			</Container>
		</section>
	)
}
