import React from "react";
import PropTypes from "prop-types";
import { Col, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, CardFooter,
  } from 'reactstrap';

const NewsArticle = (props) => {
    const { classes } = props;
	const { id, permakey, title, category, tags, date, summary } = props.article;
	const articleImage = require(`images/articles/${id}.jpg`);

	return (
		<Col sm={classes ? classes.sm : 6} md={classes ? classes.md : 4} className="my-2">
            <div className="article">
                <Card>
                    <div className="data-box">
						<h4>09</h4>
						<strong>Apr</strong>
					</div>
                    <CardImg top width="100%" src={articleImage} alt={title} />
                    <CardBody>
                        <CardSubtitle className="category">
                            <a href={`/media/category/1`}>
                                {category}
                            </a>
                        </CardSubtitle>
                        <CardTitle>
                            <a href={`/media/article/${id}/${permakey}`}>{title}</a>
                        </CardTitle>
                        <div className="card-text entry-meta meta-below">
                            <ul className="meta-left">
                                <li className="post-date">
                                    {date}
                                </li>
                                <li className="post-tags">
                                    <a href="/">&nbsp;</a>
                                    {tags && tags.map((tag, index) => {
                                        return <React.Fragment key={tag}>{index === 0 ? '':', '}<a href={`/media/tag/${tag.toLowerCase()}`} rel="tag">{tag}</a></React.Fragment>;
                                    })}
                                </li>
                            </ul>									
                        </div>
                        <CardText className="description">{summary}</CardText>
                    </CardBody>
                    <CardFooter>
                        <LinkContainer to={`/media/article/${id}/${permakey}`}>
                            <Button variant="primary">Read More</Button>
                        </LinkContainer>
                    </CardFooter>
                </Card>
            </div>
		</Col>
	);
}

NewsArticle.propTypes = {
	article: PropTypes.shape({
        id: PropTypes.number.isRequired,
        category: PropTypes.string.isRequired,
        tags: PropTypes.instanceOf(Array).isRequired,
		title: PropTypes.string.isRequired,
		date: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired
	})
};

export default NewsArticle;
